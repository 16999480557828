import { useEffect } from 'react';

const useRecordingTimer = (
  recordState: boolean,
  startTime: string | null,
  timeFrame: number,
  onTimeEnd: () => void,
) => {
  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;

    if (recordState && startTime) {
      const startDateTime = new Date(startTime);
      const now = new Date();
      const timeElapsed = now.getTime() - startDateTime.getTime();
      const timeRemaining = timeFrame * 60 * 1000 - timeElapsed;

      if (timeRemaining <= 0) {
        // Call the callback immediately if the time frame has already passed
        onTimeEnd();
      } else {
        // Set a timeout to call the callback after the remaining time
        timer = setTimeout(onTimeEnd, timeRemaining);
      }
    }

    // Clear the timeout if recordState changes or the component unmounts
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [recordState, startTime, timeFrame, onTimeEnd]);
};

export default useRecordingTimer;
