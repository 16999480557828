import { useBoundStore } from '@fixzy/agent-app/src/store';

import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../spinner/spinner';
import dayjs from 'dayjs';

interface MediaGalleryProps {
  setPreviewIndex: (val: number) => void;
}

export const PhotoGallery = ({ setPreviewIndex }: MediaGalleryProps) => {
  const takenImages = useBoundStore((state) => state.takenImages);

  return (
    <div className='flex h-1/2 flex-col border-b border-gray-200'>
      <div className='flex items-center mb-2 px-4 pt-4'>
        <h3 className='text-gray-800'>Photos</h3>
        {takenImages.length > 0 && (
          <p className='bg-secondary-100 text-sm text-secondary-700 rounded-full text-lg ml-2 px-2 py-1'>
            {takenImages.length} {takenImages.length === 1 ? 'item' : 'items'}
          </p>
        )}
      </div>
      {takenImages.length === 0 ? (
        <div className='flex-1 flex-col flex justify-center items-center bg-white rounded-xl mx-4 mb-4'>
          <div className='h-[50px] w-[50px] rounded-full bg-secondary-100 flex justify-center items-center'>
            <FontAwesomeIcon icon={faCamera} className='text-xl text-secondary-700' />
          </div>
          <h4 className='text-gray-800'>No photos</h4>
          <p>Press &quot;Photo&quot; to take a snapshot</p>
        </div>
      ) : (
        <div className='w-full overflow-y-auto'>
          <div className='flex flex-wrap pl-2'>
            {takenImages.map((image, index) => (
              <div key={`image_${index}`} className='w-1/3 pr-2 pb-2'>
                <div className='flex flex-1 bg-white h-[250px] rounded-xl justify-center items-center overflow-hidden'>
                  {image.uploading ? (
                    <div className='flex flex-col justify-center items-center'>
                      <Spinner />
                      <p className='text-gray-600 text-xs font-medium mt-1'>Uploading...</p>
                    </div>
                  ) : (
                    <div
                      className='relative h-full w-full hover:cursor-pointer'
                      onClick={() => setPreviewIndex(index)}
                    >
                      <img src={image.src} alt='#' className='h-full' data-clarity-mask='true' />
                      <div className='absolute top-0 left-0 right-0 bg-gray-100 py-1 flex items-center justify-center'>
                        <p className='text-gray-800 text-xs font-medium'>
                          {dayjs(image.timeStamp).format('DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
