import { useBoundStore } from '@fixzy/agent-app/src/store';
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from '@microsoft/signalr';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { AppType } from '@fixzy/common-package/enums';
import * as Sentry from '@sentry/react';

export type ConnectionState = 'disconnected' | 'connected' | 'joined';

export interface SignalRContext {
  sendMessage: (message: string) => void;
  sendPayload: (message: string, payload: string) => void;
  joinChannel: (channel: string) => Promise<void>;
  addCommandListener: (
    command: string,
    callback: (client: AppType) => void,
  ) => (username: string, message: string) => void;
  removeCommandListener: (callback: ReturnType<SignalRContext['addCommandListener']>) => void;
  addPayloadListener: (
    command: string,
    callback: (client: AppType, payload: string) => void,
  ) => (username: string, message: string, payload: string) => void;
  removePayloadListener: (callback: ReturnType<SignalRContext['addPayloadListener']>) => void;
  addListener: (method: string, callback: (...args: string[]) => void) => void;
  connection?: HubConnection;
  connectionState: ConnectionState;
}

const SignalRContext = createContext<SignalRContext | null>(null);

export interface SignalRProviderProps {
  hubUrl: string;
  children: React.ReactNode;
}

export const SignalRProvider = ({ hubUrl, children }: SignalRProviderProps) => {
  const [connection, setConnection] = useState<HubConnection>();
  const [connectionState, setConnectionState] = useState<ConnectionState>('disconnected');
  const appType = useBoundStore((state) => state.appType);
  const [channel, setChannel] = useState('');

  useEffect(() => {
    const connection = new HubConnectionBuilder()
      .configureLogging(LogLevel.Error)
      .withUrl(hubUrl, {
        skipNegotiation: true,
        transport: HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      .build();

    connection
      .start()
      .then(() => {
        setConnection(connection);
        setConnectionState('connected');
      })
      .catch(Sentry.captureException);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage: SignalRContext['sendMessage'] = useCallback(
    (message: string) => {
      connection?.send('sendMessage', channel, appType, message);
    },
    [appType, channel, connection],
  );

  const sendPayload: SignalRContext['sendPayload'] = useCallback(
    (message: string, payload: string) => {
      connection?.send('sendPayload', channel, appType, message, payload);
    },
    [appType, channel, connection],
  );

  const addListener: SignalRContext['addListener'] = useCallback(
    (method, callback) => {
      if (connection) {
        connection.on(method, callback);
      }
    },
    [connection],
  );

  const addCommandListener: SignalRContext['addCommandListener'] = useCallback(
    (command, callback) => {
      const listener = (username: string, message: string) => {
        if (message === command) {
          callback(username as AppType);
        }
      };
      addListener('messageReceived', listener);
      return listener;
    },
    [addListener],
  );

  const addPayloadListener: SignalRContext['addPayloadListener'] = useCallback(
    (command, callback) => {
      const listener = (username: string, message: string, payload: string) => {
        if (message === command) {
          callback(username as AppType, payload);
        }
      };
      addListener('payloadReceived', listener);
      return listener;
    },
    [addListener],
  );

  const removeCommandListener: SignalRContext['removeCommandListener'] = (callback) => {
    if (connection) {
      connection.off('messageReceived', callback);
    }
  };

  const removePayloadListener: SignalRContext['removePayloadListener'] = (callback) => {
    if (connection) {
      connection.off('payloadReceived', callback);
    }
  };

  const value: SignalRContext = {
    connectionState,
    sendMessage,
    sendPayload,
    joinChannel: async (channel) => {
      setChannel(channel);
      if (connection) {
        return connection.send('joinChannel', channel, appType).then(() => {
          setConnectionState('joined');
        });
      }
      return Promise.reject();
    },
    addListener,
    addCommandListener,
    removeCommandListener,
    addPayloadListener,
    removePayloadListener,
  };

  return <SignalRContext.Provider value={value}>{children}</SignalRContext.Provider>;
};

export const useSignalR = () => {
  const context = useContext(SignalRContext);

  if (context == null) throw new Error('SignalRContext called without provider');
  return context;
};
