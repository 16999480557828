import { useBoundStore } from '@fixzy/agent-app/src/store';
import Axios from 'axios';
import * as Sentry from '@sentry/react';

import config from '../../config';
interface EndCallModalProps {
  setToggleEndCallModal: (val: boolean) => void;
  onLeave: () => void;
}

export const EndCallModal = ({ setToggleEndCallModal, onLeave }: EndCallModalProps) => {
  const attendanceId = useBoundStore((state) => state.attendanceId);

  const leaveCall = () => {
    onLeave();
    setToggleEndCallModal(false);
    try {
      Axios.post(`${config.API_URL}/Attendance/End?attendanceId=${attendanceId}`);
    } catch (error: any) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className='flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 bg-primary-700/70 p-4'>
      <div className='bg-white rounded-xl flex flex-col p-10 justify-center items-center'>
        <div className='flex flex-col items-center max-w-[350px]'>
          <h1 className='text-secondary-700 mb-4'>Confirm Exit</h1>
          <p className='text-gray-800 text-center mb-10'>
            This will end the call for both parties, are you sure you want to exit?
          </p>
          <button
            className='bg-secondary-700 h-[50px] w-full rounded-lg text-white hover:cursor-pointer mb-4 text-lg font-bold hover:bg-secondary-500'
            onClick={leaveCall}
          >
            Yes, leave call
          </button>
          <button
            className='bg-white h-[50px] w-full rounded-lg text-secondary-700 hover:cursor-pointer text-lg font-bold border-2 border-secondary-700 hover:bg-secondary-100'
            onClick={() => setToggleEndCallModal(false)}
          >
            No, continue call
          </button>
        </div>
      </div>
    </div>
  );
};
