import React from 'react';
import clarity from '@microsoft/clarity';

interface Props {
  tooltipText: string;
  icon: React.ReactNode;
  onClick: () => void;
  active?: boolean;
  trackingLabel: string;
}

const CallActionButton = ({ tooltipText, icon, onClick, active, trackingLabel }: Props) => {
  return (
    <div className='relative flex items-center group'>
      {/* Tooltip */}
      <div className='absolute right-[65px] hidden group-hover:flex items-center px-4 py-2 bg-gray-700 text-white text-md rounded-lg shadow-md transition-opacity duration-200 whitespace-nowrap'>
        {tooltipText}
        {/* Tooltip Tail */}
        <div className='absolute right-[-6px] top-1/2 transform -translate-y-1/2 w-0 h-0 border-t-8 border-b-8 border-l-8 border-transparent border-l-gray-700'></div>
      </div>

      {/* Button */}
      <button
        className={`rounded-full border-2 w-[60px] h-[60px] flex flex-col justify-center items-center ${
          active
            ? 'bg-white border-secondary-700 text-secondary-700 hover:bg-secondary-100'
            : 'bg-secondary-700 border-white text-white hover:bg-secondary-500'
        }`}
        onClick={() => {
          clarity.event(`${trackingLabel} clicked`);
          onClick();
        }}
        aria-label={trackingLabel}
      >
        {icon}
      </button>
    </div>
  );
};

export default CallActionButton;
