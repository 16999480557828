import { useEffect } from 'react';

export const useKeybind = (callback: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    const handleKeypress = callback;

    document.addEventListener('keydown', handleKeypress);

    return () => document.removeEventListener('keydown', handleKeypress);
  }, [callback]);
};
