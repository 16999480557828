import './pausedOverlay.scss';

type PausedOverlayProps = {
  img: string;
};

export const PausedOverlay = ({ img }: PausedOverlayProps) => (
  <div id='pause-overlay' className='img-container'>
    <img
      data-clarity-mask='true'
      id='pause-image'
      src={img}
      alt='pause'
      className='h-full mx-auto'
    />
    <div className='pause-alert'>
      Camera Paused <span className='pause-circle'></span>
    </div>
  </div>
);
