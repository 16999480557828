/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
export default {
  categories: {
    necessary: {
      enabled: true, // this category is enabled by default
      readOnly: true, // this category cannot be disabled
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: '_clck',
          },
          {
            name: '_clsk',
          },
          {
            name: 'CLID',
          },
        ],
      },
    },
  },

  language: {
    default: 'en',
    translations: {
      en: {
        consentModal: {
          title: 'Cookies on Fixzy',
          description:
            'We use some essential cookies to make this service work.<br/><br/>We’d also like to use analytics cookies so we can understand how you use the service and make improvements.',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          showPreferencesBtn: 'Manage Individual preferences',
        },
        preferencesModal: {
          title: 'Manage cookie preferences',
          acceptAllBtn: 'Accept all',
          acceptNecessaryBtn: 'Reject all',
          savePreferencesBtn: 'Accept current selection',
          closeIconLabel: 'Close modal',
          sections: [
            {
              title: 'Strictly Necessary cookies',
              description:
                'These cookies are essential for the proper functioning of the website and cannot be disabled.',

              //this field will generate a toggle linked to the 'necessary' category
              linkedCategory: 'necessary',
            },
            {
              title: 'Performance and Analytics',
              description:
                'These cookies collect information about how you use our website. All of the data is anonymized and cannot be used to identify you.',
              linkedCategory: 'analytics',
              cookieTable: {
                headers: {
                  name: 'Name',
                  domain: 'Service',
                  description: 'Description',
                  expiration: 'Expiration',
                },
                body: [
                  {
                    name: '_clck',
                    domain: 'Microsoft Clarity',
                    expiration: 'Expires in one year',
                    description:
                      'Persists the Clarity User ID and preferences, unique to that site is attributed to the same user ID.',
                  },
                  {
                    name: '_clsk',
                    domain: 'Microsoft Clarity',
                    expiration: 'Expires in one year',
                    description:
                      'Connects multiple page views by a user into a single Clarity session recording.',
                  },
                  {
                    name: 'CLID',
                    domain: 'Microsoft Clarity',
                    expiration: 'Expires in one year',
                    description:
                      'Identifies the first-time Clarity saw this user on any site using Clarity.',
                  },
                ],
              },
            },
            {
              title: 'More information',
              description:
                'For further information on how we store and use data please see our <a href="https://fixzy.ai/privacy-policy" target="_blank">Privacy policy</a>',
            },
          ],
        },
      },
    },
  },
};
