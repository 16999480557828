import { useState, useEffect, ReactElement, useMemo } from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { useBoundStore } from '@fixzy/agent-app/src/store';

dayjs.extend(duration);

function Timer(): ReactElement {
  const recordingStartTime = useBoundStore((state) => state.startTime);

  const startDateTime = useMemo(() => new Date(recordingStartTime).getTime(), [recordingStartTime]);
  const [timer, setTimer] = useState('00:00');

  useEffect(() => {
    const ms = 1000;

    const intervalId = setInterval(() => {
      const d = dayjs.duration(Date.now() - startDateTime);

      setTimer(d.format(d.hours() >= 1 ? 'HH:mm:ss' : 'mm:ss'));
    }, ms);

    return () => clearInterval(intervalId);
  }, [startDateTime]);

  return <>{timer}</>;
}

export default Timer;
