import { ReactElement, useCallback, useEffect, useState, useRef } from 'react';

import { AppType, MenuItems, PointerRtcActions } from '../../../enums';
import {
  toRelPosition,
  toAbsoluteX,
  toAbsoluteY,
  RelPosition,
  clampRelativeCoord,
} from '../callTools/drawing';
import { useBoundStore } from '@fixzy/agent-app/src/store';
import { ReactComponent as Cross } from '@fixzy/icon-library/src/icons/cross-record.svg';

import '../pointer/pointer.scss';
import { useSignalR } from '../../hooks';

interface PointerProps {
  width: number;
  height: number;
  rect: DOMRect;
  onPositionUpdated: (position: RelPosition) => void;
  onPositionReset: () => void;
}

function Pointer(props: PointerProps): ReactElement {
  const { onPositionReset, onPositionUpdated, width, height, rect } = props;

  const [appType, selectedMenuItem] = useBoundStore((state) => [
    state.appType,
    state.selectedMenuItem,
  ]);

  const {
    sendMessage,
    sendPayload,
    addPayloadListener,
    removePayloadListener,
    addCommandListener,
    removeCommandListener,
    connectionState,
  } = useSignalR();

  const isConsumer = appType === AppType.consumer;
  const isAgent = appType === AppType.agent;

  const [x, setX] = useState(isConsumer ? -1 : 0.5);
  const [y, setY] = useState(isConsumer ? -1 : 0.5);

  const shouldUpdate = useRef(false);

  const updatePositionTimeout = useRef<NodeJS.Timeout | null>(null);

  const resetPosition = useCallback(async () => {
    setX(-1);
    setY(-1);

    if (onPositionReset) onPositionReset();

    if (isAgent) {
      sendMessage(PointerRtcActions.resetPosition);
    }
  }, [isAgent, onPositionReset, sendMessage]);

  const updatePosition = useCallback(
    async (position: RelPosition, forceUpdate = false) => {
      if (isConsumer || shouldUpdate.current || forceUpdate) {
        setX(clampRelativeCoord(position.x));
        setY(clampRelativeCoord(position.y));

        if (position.x < 0 || position.x > 1 || position.y < 0 || position.y > 1)
          shouldUpdate.current = false;

        if (onPositionUpdated) onPositionUpdated(position);

        if (isAgent && !updatePositionTimeout.current) {
          updatePositionTimeout.current = setTimeout(async () => {
            sendPayload(PointerRtcActions.updatePosition, JSON.stringify(position));

            updatePositionTimeout.current = null;
          }, 20);
        }
      }
    },
    [isConsumer, isAgent, onPositionUpdated, sendPayload],
  );

  const centerPosition = useCallback(async () => {
    setX(0.5);
    setY(0.5);

    // set position on consumer
    updatePosition({ x: 0.5, y: 0.5, ratio: 1 }, true);

    if (onPositionUpdated) onPositionUpdated({ x: 0.5, y: 0.5, ratio: width / height });
  }, [height, width, onPositionUpdated, updatePosition]);

  const onUpdatePosition = useCallback(
    (x: number, y: number) => {
      updatePosition(toRelPosition({ x: x, y: y }, { width: width, height: height }));
    },
    [height, width, updatePosition],
  );

  useEffect(() => {
    // Hides the pointer in the consumer's app
    if (isAgent && !selectedMenuItem && (x >= 0 || y >= 0)) resetPosition();

    // Pointer starts in the middle of the screen
    if (isAgent && selectedMenuItem && x < 0 && y < 0) centerPosition();
  }, [
    isAgent,
    isConsumer,
    selectedMenuItem,
    x,
    y,
    onUpdatePosition,
    updatePosition,
    resetPosition,
    width,
    height,
    centerPosition,
  ]);

  useEffect(() => {
    if (connectionState === 'joined' && isConsumer) {
      const updateListener = addPayloadListener(PointerRtcActions.updatePosition, (_, payload) =>
        updatePosition(JSON.parse(payload)),
      );
      const resetListener = addCommandListener(PointerRtcActions.resetPosition, () =>
        resetPosition(),
      );

      return () => {
        removeCommandListener(resetListener);
        removePayloadListener(updateListener);
      };
    }
  }, [
    addCommandListener,
    addPayloadListener,
    connectionState,
    isConsumer,
    removeCommandListener,
    removePayloadListener,
    resetPosition,
    updatePosition,
  ]);

  const onMouseDown = () => {
    shouldUpdate.current = true;
  };

  const onMouseUp = () => {
    shouldUpdate.current = false;
  };

  const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isAgent && shouldUpdate.current) {
      onUpdatePosition(e.clientX - rect.x, e.clientY - rect.y);
    }
  };

  return (isConsumer && x >= 0 && y >= 0) || selectedMenuItem === MenuItems.pointer ? (
    <div
      tabIndex={0}
      role='button'
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onKeyDown={() => {}}
      className='pointer-container'
      style={{
        width: width,
        height: height,
      }}
      onMouseDown={(e) => {
        if (isAgent) {
          onMouseDown();
          onUpdatePosition(e.clientX - rect.x, e.clientY - rect.y);
        }
      }}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
    >
      <button
        className='pointer'
        style={{
          top: toAbsoluteY(y, width, width / height),
          left: toAbsoluteX(x, width),
        }}
        onMouseDown={onMouseDown}
      >
        <Cross width={32} height={32} />
      </button>
    </div>
  ) : (
    <></>
  );
}

export default Pointer;
