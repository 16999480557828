import { useBoundStore } from '@fixzy/agent-app/src/store';
import { ChannelMessage, MenuItems } from '@fixzy/common-package/enums';
import { ReactComponent as Capture } from '@fixzy/icon-library/src/icons/capture.svg';
import { ReactComponent as Pause } from '@fixzy/icon-library/src/icons/pause-circle.svg';
import { ReactComponent as PenTool } from '@fixzy/icon-library/src/icons/pen-tool.svg';
import { ReactComponent as Pointer } from '@fixzy/icon-library/src/icons/pointer.svg';
import { ReactComponent as Share } from '@fixzy/icon-library/src/icons/share.svg';
import { ReactComponent as OpenApp } from '@fixzy/icon-library/src/icons/open-app.svg';
import { ReactComponent as Play } from '@fixzy/icon-library/src/icons/play-icon.svg';
import { uploadBase64Photo } from '../../api/helpers';
import { CanvasImage } from '../callTools/drawing';

import { useSignalR } from '../../hooks';
import StreamMixer from '../callTools/streamMixer';
import CallActionButton from './callActionButton';
import toast from 'react-hot-toast';

export interface CallOverlayActionsProps {
  video?: Element | null | undefined;
  paused: string;
  setPaused: (screenshot: string) => void;
  onScreenShare: () => void;
  onSendToAppClick: () => void;
  pointerImage?: CanvasImage | null;
  streamMixer?: React.MutableRefObject<StreamMixer | undefined>;
  penEnabled: boolean;
  pointerEnabled: boolean;
  sharingEnabled: boolean;
}

export const CallOverlayActions = ({
  pointerImage,
  video,
  paused,
  setPaused,
  onScreenShare,
  onSendToAppClick,
  streamMixer,
  penEnabled,
  pointerEnabled,
  sharingEnabled,
}: CallOverlayActionsProps) => {
  const { sendPayload, sendMessage } = useSignalR();
  const [attendanceId, selectedMenuItem, setSelectedMenuItem] = useBoundStore((state) => [
    state.attendanceId,
    state.selectedMenuItem,
    state.setSelectedMenuItem,
  ]);

  const takeScreenShot = (captureTools = true): string | null => {
    const videoElement = (
      video ? video : document.querySelector('video.agora_video_player')
    ) as HTMLVideoElement;

    if (videoElement) {
      const w = videoElement.videoWidth;
      const h = videoElement.videoHeight;

      const canvas = document.createElement('canvas');

      canvas.width = w;
      canvas.height = h;

      const canvasContext = canvas.getContext('2d');

      if (canvasContext) {
        canvasContext.drawImage(videoElement, 0, 0, w, h);

        // Capture Pen
        const drawingCanvas = document.querySelector('.drawing-scene canvas') as HTMLCanvasElement;

        if (captureTools && drawingCanvas) canvasContext.drawImage(drawingCanvas, 0, 0, w, h);

        // Capture Pointer
        if (captureTools && pointerImage && pointerImage.position)
          canvasContext.drawImage(
            pointerImage.image,
            pointerImage.position.x -
              ((pointerImage.image as HTMLImageElement).width as number) / 2,
            pointerImage.position.y -
              ((pointerImage.image as HTMLImageElement).height as number) / 2,
          );
      }

      return canvas.toDataURL('image/png');
    }

    return null;
  };

  const generateFinalImage = (captureTools = true) => {
    if (paused) {
      const image = new Image();
      image.src = paused;

      const w = image.width;
      const h = image.height;

      const canvas = document.createElement('canvas');

      canvas.width = w;
      canvas.height = h;

      const canvasContext = canvas.getContext('2d');

      if (canvasContext) {
        canvasContext.drawImage(image, 0, 0, w, h);

        // Capture Drawings
        const drawingCanvas = document.querySelector('.drawing-scene canvas') as HTMLCanvasElement;

        if (captureTools && drawingCanvas) canvasContext.drawImage(drawingCanvas, 0, 0, w, h);

        // Capture Pointer
        if (captureTools && pointerImage && pointerImage.position)
          canvasContext.drawImage(
            pointerImage.image,
            pointerImage.position.x -
              ((pointerImage.image as HTMLImageElement).width as number) / 2,
            pointerImage.position.y -
              ((pointerImage.image as HTMLImageElement).height as number) / 2,
          );
      }

      return canvas.toDataURL('image/png');
    }

    return null;
  };

  const onVideoImageCapture = async () => {
    const screenshot = takeScreenShot();

    if (screenshot) {
      toast.success('Snapshot captured');
      await uploadBase64Photo(screenshot, attendanceId, sendMessage);
    }
  };

  const onPausedImageCapture = async () => {
    const pausedImage = generateFinalImage();

    if (pausedImage) {
      toast.success('Snapshot captured');
      await uploadBase64Photo(pausedImage, attendanceId, sendMessage);
    }
  };

  const onPaused = async () => {
    const videoElement = (
      video ? video : document.querySelector('video.agora_video_player')
    ) as HTMLVideoElement;

    const w = videoElement.videoWidth;
    const h = videoElement.videoHeight;

    if (paused) {
      setPaused('');
      sendMessage(ChannelMessage.unpaused);

      if (streamMixer?.current?.recordingLayers.paused) {
        streamMixer.current.recordingLayers.paused = null;
      }
    } else {
      const screenshot = takeScreenShot(false);
      sendPayload(ChannelMessage.paused, screenshot || '');

      if (screenshot && streamMixer?.current) {
        const image = new Image();
        image.src = screenshot;
        streamMixer.current.recordingLayers.paused = {
          image,
          position: {
            x: w / 2,
            y: h / 2,
          },
        };
      }

      if (screenshot) setPaused(screenshot);
    }
  };

  return (
    <div className='absolute top-4 right-4 bottom-4 flex flex-col gap-4 justify-center'>
      <CallActionButton
        onClick={onPaused}
        active={!!paused}
        tooltipText={paused ? 'Play' : 'Pause'}
        icon={
          paused ? (
            <Play className={'icon'} height={'30px'} width={'30px'} />
          ) : (
            <Pause className={'icon'} height={'30px'} width={'30px'} />
          )
        }
        trackingLabel='Pause button'
      />
      <CallActionButton
        onClick={paused ? onPausedImageCapture : onVideoImageCapture}
        tooltipText='Take photo'
        icon={<Capture className={'icon'} height={'30px'} width={'30px'} />}
        trackingLabel='Take photo'
      />
      <CallActionButton
        onClick={() =>
          setSelectedMenuItem(selectedMenuItem === MenuItems.penTool ? null : MenuItems.penTool)
        }
        active={penEnabled}
        icon={
          <PenTool
            className={`mb-1 ${penEnabled ? 'fill-secondary-700' : 'fill-white'}`}
            height={'25px'}
            width={'25px'}
          />
        }
        tooltipText={penEnabled ? 'Pen tool off' : 'Pen tool on'}
        trackingLabel='Pen tool'
      />
      <CallActionButton
        active={pointerEnabled}
        onClick={() =>
          setSelectedMenuItem(selectedMenuItem === MenuItems.pointer ? null : MenuItems.pointer)
        }
        icon={
          <Pointer
            className={`${pointerEnabled ? 'fill-secondary-700' : 'fill-white'}`}
            height={'30px'}
            width={'30px'}
          />
        }
        tooltipText={pointerEnabled ? 'Pointer off' : 'Pointer on'}
        trackingLabel='Pointer tool'
      />
      <CallActionButton
        onClick={onSendToAppClick}
        icon={<OpenApp className='fill-white' height={'30px'} width={'30px'} />}
        tooltipText='Open measure app'
        trackingLabel='Open measure app'
      />
      <CallActionButton
        onClick={onScreenShare}
        active={sharingEnabled}
        icon={
          <Share
            className={`${sharingEnabled ? 'fill-secondary-700' : 'fill-white'}`}
            height={'30px'}
            width={'30px'}
          />
        }
        tooltipText='Screen share'
        trackingLabel='Screen share'
      />
    </div>
  );
};
