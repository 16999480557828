import { useEffect, useState } from 'react';
import shallow from 'zustand/shallow';

import { useBoundStore } from '@fixzy/agent-app/src/store';
import { AppType, MenuItems, PenToolActions } from '@fixzy/common-package/enums';
import { ReactComponent as Phone } from '@fixzy/icon-library/src/icons/phone.svg';
import { ReactComponent as Mute } from '@fixzy/icon-library/src/icons/microphone.svg';
import { ReactComponent as CameraOff } from '@fixzy/icon-library/src/icons/camera-on.svg';
import { ReactComponent as Undo } from '@fixzy/icon-library/src/icons/undo.svg';
import { ReactComponent as Redo } from '@fixzy/icon-library/src/icons/redo.svg';
import { ReactComponent as Delete } from '@fixzy/icon-library/src/icons/delete.svg';
import { ReactComponent as Palette } from '@fixzy/icon-library/src/icons/palette.svg';
import { ReactComponent as ChevronLeft } from '@fixzy/icon-library/src/icons/chevron-left.svg';

import '../callNavBar/callNavBar.scss';
import { useKeybind } from '../../hooks';
import Logo from '../logo/Logo';

export const NAV_BAR_HEIGHT = 85;

interface NavBarProps {
  toggleMute: () => void;
  muted: boolean;
  setEndCall: React.Dispatch<boolean>;
}

export const CallNavBar = ({ toggleMute, muted, setEndCall }: NavBarProps) => {
  const [showUnableToEndCall, setShowUnableToEndCall] = useState(false);

  const [
    appType,
    recordState,
    setRecordState,
    selectedMenuItem,
    setSelectedMenuItem,
    setPenToolAction,
    setStartTime,
    setEndTime,
    uploadState,
  ] = useBoundStore(
    (state) => [
      state.appType,
      state.recordState,
      state.setRecordState,
      state.selectedMenuItem,
      state.setSelectedMenuItem,
      state.setPenToolAction,
      state.setStartTime,
      state.setEndTime,
      state.uploadState,
    ],
    shallow,
  );

  useEffect(() => {
    if ((!recordState || !uploadState) && showUnableToEndCall) {
      setShowUnableToEndCall(false);
    }
  }, [recordState, uploadState]);

  useKeybind((event) => {
    if (event.key === 'm' && document.activeElement?.id !== 'note-input') {
      toggleMute();
    }
  });

  const onEndCall = () => {
    if (recordState || uploadState) {
      setShowUnableToEndCall(true);
    } else {
      setEndCall(true);
    }
  };

  const renderEndCallButton = () => (
    <div className='relative flex mt-[-40px] bg-primary-700 rounded-full justify-center items-center w-[80px] h-[80px]'>
      <button
        className={`end-call ${recordState || uploadState ? 'disabled' : ''}`}
        tabIndex={0}
        onClick={onEndCall}
      >
        <Phone className='phone-icon' height={'32px'} width={'32px'} />
      </button>
      {showUnableToEndCall && (
        <div className='unable-to-end-call'>
          You can not end the call whilst {uploadState ? 'uploading' : 'recording'}.
        </div>
      )}
    </div>
  );

  const renderMuteButton = () => (
    <div className='flex flex-1 justify-center'>
      <button
        className='relative flex flex-col justify-center items-center stroke-white text-white'
        onClick={() => toggleMute()}
      >
        <Mute height={'32px'} width={'32px'} />
        {muted ? (
          <div className='absolute top-4 left-2 bg-error-600 h-[4px] w-[40px] transform rotate-45 ' />
        ) : null}
        <span>{muted ? 'Unmute' : 'Mute'}</span>
      </button>
    </div>
  );

  const renderBackButton = () => (
    <div className='flex flex-1 justify-center'>
      <button
        className='flex h-[48px] w-[48px] rounded-full justify-center items-center bg-white'
        tabIndex={0}
        onClick={() => setSelectedMenuItem(null)}
      >
        <ChevronLeft
          height={'32px'}
          width={'32px'}
          className='stroke-secondary-700 stroke-2 mr-1'
        />
      </button>
    </div>
  );

  const renderAgentButtons = () => {
    switch (selectedMenuItem) {
      case MenuItems.pointer:
        return (
          <>
            {renderBackButton()}
            {renderEndCallButton()}
            <div className='flex-1' />
          </>
        );
      case MenuItems.penTool:
        return (
          <>
            {renderBackButton()}
            <div className='flex flex-1 justify-center'>
              <button
                className='flex flex-col rounded-full justify-center items-center text-white stroke-white'
                onClick={() => setPenToolAction(PenToolActions.undo)}
              >
                <Undo height={'32px'} width={'32px'} />
                <div>Undo</div>
              </button>
            </div>
            <div className='flex flex-1 justify-center'>
              <button
                className='flex flex-col rounded-full justify-center items-center text-white stroke-white'
                onClick={() => setPenToolAction(PenToolActions.redo)}
              >
                <Redo height={'32px'} width={'32px'} />
                <div>Redo</div>
              </button>
            </div>
            {renderEndCallButton()}
            <div className='flex flex-1 justify-center'>
              <button
                className='flex flex-col rounded-full justify-center items-center text-white stroke-white'
                onClick={() => setPenToolAction(PenToolActions.clear)}
              >
                <Delete height={'32px'} width={'32px'} />
                <div>Delete</div>
              </button>
            </div>
            <div className='flex flex-1 justify-center'>
              <button
                className='flex flex-col rounded-full justify-center items-center text-white stroke-white fill-white'
                onClick={() => setPenToolAction(PenToolActions.toggleColorPicker)}
              >
                <Palette height={'32px'} width={'32px'} />
                <div>Colour</div>
              </button>
            </div>
            <div className='flex-1' />
          </>
        );
      default:
        return (
          <>
            <div className='flex flex-1 justify-center'>
              <button
                className='relative flex flex-col items-center stroke-white text-white'
                onClick={() => {
                  const time = new Date().toISOString();
                  recordState ? setEndTime(time) : setStartTime(time);
                  setRecordState(!recordState);
                }}
              >
                {recordState ? (
                  <div className='h-[35px] w-[35px] bg-error-600 rounded-full flex justify-center items-center'>
                    <div className='h-[15px] w-[15px] bg-white rounded-sm' />
                  </div>
                ) : (
                  <CameraOff height={'32px'} width={'32px'} />
                )}
                <span>{recordState ? 'Stop' : 'Record'}</span>
              </button>
            </div>
            {renderEndCallButton()}
            {renderMuteButton()}
          </>
        );
    }
  };

  const renderConsumerButtons = () => {
    return (
      <>
        <div className='flex flex-1 justify-start'>
          <div className='w-3/4'>
            <Logo />
          </div>
        </div>
        {renderEndCallButton()}
        {renderMuteButton()}
      </>
    );
  };

  return (
    <>
      <div className={`flex bg-primary-700 h-[85px] items-center`}>
        <div className='flex flex-1 items-center px-2'>
          {appType === AppType.consumer ? renderConsumerButtons() : renderAgentButtons()}
        </div>
      </div>
    </>
  );
};
