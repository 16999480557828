import './callStream.scss';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AgoraVideoPlayer, ILocalVideoTrack } from 'agora-rtc-react';
import AgoraRTC, { ClientConfig } from 'agora-rtc-react';

import shallow from 'zustand/shallow';

import { useBoundStore } from '@fixzy/agent-app/src/store';
import {
  AppType,
  ChannelMessage,
  JoinState,
  PenToolActions,
  PenToolRtcActions,
} from '@fixzy/common-package/enums';

import { LoadingScreen } from '../loadingScreen/loadingScreen';
import { CallNavBar } from '../callNavBar/callNavBar';
import useAgora from './useAgora';
import CallTools from '../callTools/callTools';
import { OpenAppButton } from '../buttons/openAppButton';

import { EndCallModal } from '../endCallModal/endCallModal';
import { PhotoGallery } from '../photoGallery/photoGallery';
import { ImagePreview } from '../imagePreview/imagePreview';
import { VideoGallery } from '../videoGallery/videoGallery';
import { useSignalR } from '../../hooks';
import { NotesGallery } from '../notes/notesGallery';
import { useKeybind } from '../../hooks';
import configJson from '../../config';
import { beforeUnloadListener } from './beforeUnloadListener';
import { getFFmpeg } from '../callTools/getFFmpeg';
import Axios from 'axios';
import Logo, { LogoVariant } from '../logo/Logo';

const token: string | null = '';

const config: ClientConfig = {
  mode: 'rtc',
  codec: 'vp9',
};

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

// if in australia hard code to asia server (oceania server doesnt exist)
if (timezone && timezone.toUpperCase().includes('AUSTRALIA')) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  AgoraRTC.setArea('ASIA');
}

const client = AgoraRTC.createClient(config);

AgoraRTC.setLogLevel(3);

export const CallScreen = () => {
  const navigate = useNavigate();
  const { guid } = useParams();

  const [
    attendanceId,
    setAttendanceId,
    appType,
    addNote,
    notes,
    consumerScreenShareState,
    showOpenAppButton,
    setShowOpenAppButton,
    selectedMenuItem,
    setSelectedMenuItem,
    streamSentState,
    setPenToolAction,
  ] = useBoundStore(
    (state) => [
      state.attendanceId,
      state.setAttendanceId,
      state.appType,
      state.addNote,
      state.notes,
      state.consumerScreenShareState,
      state.showOpenAppButton,
      state.setShowOpenAppButton,
      state.selectedMenuItem,
      state.setSelectedMenuItem,
      state.streamSentState,
      state.setPenToolAction,
    ],
    shallow,
  );

  const isConsumer = appType === AppType.consumer;

  useEffect(() => {
    setAttendanceId(guid as string);
    getFFmpeg(); // load ffmpeg straight away
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    join,
    joinState,
    leave,
    localAudioTrack,
    localVideoTrack,
    remoteUsers,
    screenShare,
    isPublishAgain,
    audioTracks,
    toggleMute,
    muted,
    readyToJoin,
  } = useAgora(client);

  const { sendMessage } = useSignalR();

  useKeybind((event) => {
    if (event.key === 'Escape' && selectedMenuItem !== null) {
      setSelectedMenuItem(null);
    }
  });

  const [toggleEndCallModal, setToggleEndCallModal] = useState(false);
  const [previewIndex, setPreviewIndex] = useState<number | null>(null);
  const channelId = attendanceId || guid || '';

  useEffect(() => {
    window.addEventListener('beforeunload', beforeUnloadListener);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, []);

  useEffect(() => {
    if (readyToJoin && joinState === JoinState.inactive) {
      join(configJson.agora.APP_ID, channelId, token);
    }
  }, [readyToJoin, joinState, channelId, join]);

  useEffect(() => {
    if (streamSentState === false && appType === AppType.consumer) {
      isPublishAgain();
    }
  }, [streamSentState]);

  const isAgent = appType === AppType.agent;
  const remoteVideo = remoteUsers.length && remoteUsers[remoteUsers.length - 1].videoTrack; // set to the last use joined to switch to app screen share

  const ready =
    joinState === JoinState.joined &&
    !!localAudioTrack &&
    (isConsumer ? !!localVideoTrack : !!remoteVideo);

  const shouldShowOpenApp = !isAgent && showOpenAppButton;

  const consumerName = isAgent ? 'Customer' : 'Agent';

  useEffect(() => {
    if (
      remoteUsers.length &&
      remoteUsers[remoteUsers.length - 1].videoTrack === undefined &&
      appType === AppType.agent
    ) {
      return sendMessage(ChannelMessage.isStreamNotSent);
    }
    if (appType === AppType.agent) sendMessage(ChannelMessage.isStreamSent);
  }, [remoteVideo]);

  const onScreenShare = () => {
    setPenToolAction(PenToolActions.clear);
    sendMessage(PenToolRtcActions.clear);
    setSelectedMenuItem(null);
    screenShare();
  };

  return (
    <div className='w-full h-full'>
      {ready ? (
        <div className='flex h-full'>
          {isAgent && (
            <div className='flex flex-1 flex-col border-r border-gray-200 bg-gray-50'>
              <div className='flex flex-1 flex-col overflow-hidden'>
                <PhotoGallery setPreviewIndex={setPreviewIndex} />
                <VideoGallery />
              </div>
              <div className=' flex justify-center items-center py-4 border-t border-gray-200'>
                <div className='w-1/2'>
                  <Logo variant={LogoVariant.Primary} />
                </div>
              </div>
            </div>
          )}

          <div className='flex flex-[2_2_0%] flex-col h-full'>
            <div
              className={`relative flex justify-center items-center bg-white ${
                isAgent ? 'h-[500px]' : 'flex-1'
              }`}
            >
              <AgoraVideoPlayer
                className='h-[100%] w-[100%]'
                config={{ fit: 'contain', mirror: false }}
                videoTrack={
                  (isAgent || consumerScreenShareState) && remoteVideo
                    ? remoteVideo
                    : (localVideoTrack as ILocalVideoTrack)
                }
              />
              <CallTools
                onScreenShare={onScreenShare}
                onSendToAppClick={() => sendMessage(ChannelMessage.openApp)}
                leave={leave}
                consumerName={consumerName}
                audioTracks={audioTracks}
              />
            </div>

            <div className='flex flex-col'>
              {shouldShowOpenApp && (
                <OpenAppButton
                  attendanceId={attendanceId}
                  onClickClose={() => setShowOpenAppButton(false)}
                />
              )}
              <CallNavBar
                toggleMute={toggleMute}
                muted={muted}
                setEndCall={setToggleEndCallModal}
              />
            </div>
            {isAgent ? (
              <div className='flex flex-col flex-1 bg-gray-50 overflow-hidden'>
                <NotesGallery notes={notes} attendanceId={guid || attendanceId} addNote={addNote} />
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        <LoadingScreen
          name={consumerName}
          onLeave={() => {
            // end the call and nav to the end call screen
            sendMessage(ChannelMessage.callHasEnded);
            Axios.post(`${configJson.API_URL}/Attendance/End?attendanceId=${attendanceId}`);
            navigate('/endcall');
          }}
        />
      )}
      {previewIndex != null && (
        <ImagePreview previewIndex={previewIndex} setPreviewIndex={setPreviewIndex} />
      )}
      {toggleEndCallModal ? (
        <EndCallModal
          setToggleEndCallModal={setToggleEndCallModal}
          onLeave={() => sendMessage(ChannelMessage.callHasEnded)}
        />
      ) : null}
    </div>
  );
};
