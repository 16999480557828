import { useState, useEffect, useRef } from 'react';
import AgoraRTC, {
  IAgoraRTCClient,
  IAgoraRTCRemoteUser,
  ILocalVideoTrack,
  ILocalAudioTrack,
  ICameraVideoTrack,
  IMicrophoneAudioTrack,
  ITrack,
} from 'agora-rtc-react';
import { isMobile } from 'react-device-detect';

import { useBoundStore } from '@fixzy/agent-app/src/store';
import {
  AppType,
  ChannelMessage,
  JoinState,
  PenToolRtcActions,
  PointerRtcActions,
  ProxyModes,
} from '../../../enums';
import { useSignalR } from '../../hooks';

import { detect } from 'detect-browser';

const localTracks: { videoTrack?: ICameraVideoTrack; audioTrack?: IMicrophoneAudioTrack } = {};

interface AgoraCall {
  localAudioTrack: ILocalAudioTrack | undefined;
  localVideoTrack: ILocalVideoTrack | undefined;
  joinState: JoinState;
  setJoinState: (joinState: number) => void;
  leave: () => void;
  screenShare: () => void;
  join: (appid: string, channel: string, token?: string, uid?: string | number | null) => void;
  remoteUsers: IAgoraRTCRemoteUser[];
  isPublishAgain: () => void;
  audioTracks: ITrack[];
  toggleMute: () => void;
  muted: boolean;
  readyToJoin: boolean;
}

export type RemoteAction = {
  action: PenToolRtcActions | PointerRtcActions;
  data?: any;
};

export default function useAgora(client: IAgoraRTCClient | undefined): AgoraCall {
  const setScreenShareState = useBoundStore((state) => state.setScreenShareState);
  const screenShareState = useBoundStore((state) => state.screenShareState);
  const appType = useBoundStore((state) => state.appType);

  const isConsumer = appType === AppType.consumer;

  const [localVideoTrack, setLocalVideoTrack] = useState<ILocalVideoTrack | undefined>(undefined);
  const [localAudioTrack, setLocalAudioTrack] = useState<ILocalAudioTrack | undefined>(undefined);
  const [screenTrack, setScreenTrack] = useState<ILocalVideoTrack>();

  const [readyToJoin, setReadyToJoin] = useState(false);

  const [muted, setMuted] = useState(false);

  // All audio tracks
  const audioTracks = useRef<ITrack[]>([]);

  const { sendMessage } = useSignalR();

  const [joinState, setJoinState] = useState<JoinState>(JoinState.inactive);

  const [remoteUsers, setRemoteUsers] = useState<IAgoraRTCRemoteUser[]>([]);

  async function join(appid: string, channel: string, token?: string) {
    setJoinState(JoinState.joining);

    if (!client) return;

    const browser = detect();

    let rearCamera = undefined;

    if (isConsumer) {
      const cameras = await AgoraRTC.getCameras();

      if (browser?.os?.includes('Android')) {
        rearCamera = cameras.find((camera: any) =>
          camera.label.toLocaleLowerCase().includes('facing back'),
        );
      } else if (browser?.os?.includes('iOS')) {
        rearCamera = cameras.find((camera: any) =>
          camera.label.toLocaleLowerCase().includes('back'),
        );
      }
    }

    await client.join(appid, channel, token || null, null);
    if (!localTracks.audioTrack) {
      localTracks.audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
        encoderConfig: 'high_quality',
        ANS: true,
        AEC: true,
        AGC: true,
      });
    }

    let newIdealHeight = 0;
    let newIdealWidth = 0;

    if (isConsumer && !localTracks.videoTrack) {
      const originalWidth = window.innerWidth;
      const originalHeight = window.innerHeight - 83;
      const idealHeight = 854;
      const idealWidth = 480;

      // Determine scaling factors for width and height
      const idealWidthScale = idealWidth / originalWidth;
      const idealHeightScale = idealHeight / originalHeight;

      // Choose the smaller scaling factor to ensure both dimensions fit within the limits
      const idealScale = Math.min(idealWidthScale, idealHeightScale);

      // Apply scaling to both width and height (whether scaling up or down)
      newIdealWidth = Math.ceil(originalWidth * idealScale);
      newIdealHeight = Math.ceil(originalHeight * idealScale);

      localTracks.videoTrack = await AgoraRTC.createCameraVideoTrack({
        cameraId: rearCamera?.deviceId,
        ...(isMobile // AGORA BUG WORKAROUND: on mobile the width and height are flipped
          ? {
              encoderConfig: {
                width: newIdealHeight,
                height: newIdealWidth,
              },
            }
          : {}),
      });
    }

    audioTracks.current = [...audioTracks.current, localTracks.audioTrack];

    setLocalAudioTrack(localTracks.audioTrack);
    if (isConsumer) setLocalVideoTrack(localTracks.videoTrack);

    await client.publish(Object.values(localTracks));
    setJoinState(JoinState.joined);

    // WORK AROUND: setting the encoder config after the track has published seems to enforce this resolution better
    if (isConsumer && isMobile) {
      localTracks.videoTrack?.setEncoderConfiguration({
        width: newIdealHeight,
        height: newIdealWidth,
      });
    }
  }

  useEffect(() => {
    const endShare = async () => {
      if (client) {
        await sendMessage(ChannelMessage.isNotScreenShare);
        screenTrack?.stop();
        screenTrack?.removeAllListeners();
        await client.unpublish();

        await client.publish(Object.values(localTracks));
      }
    };

    // When screen share state is set to false end the screen share for the consumer
    if (!screenShareState) {
      endShare();
    }
  }, [screenShareState, client, localTracks, screenTrack]);

  const toggleMute = () => {
    const isMuted = !muted;

    if (localAudioTrack) {
      // toggle mute
      localAudioTrack.setMuted(isMuted);
      if (isMuted) {
        // remove mic from audio array
        audioTracks.current = audioTracks.current.filter((x) => !x.getTrackId().includes('mic'));
      } else {
        // add mic to audio array
        audioTracks.current = [...audioTracks.current, localAudioTrack];
      }
    }
    setMuted(isMuted);
  };

  async function screenShare() {
    if (!client) return;

    const newScreenShareState = !screenShareState;

    if (newScreenShareState) {
      const screenTrack = await AgoraRTC.createScreenVideoTrack({}, 'disable');
      await sendMessage(ChannelMessage.isScreenShare);

      screenTrack.on('track-ended', () => {
        setScreenShareState(false);
      });

      await client.unpublish(localTracks.videoTrack);
      await client.publish(screenTrack);
      setScreenTrack(screenTrack);
    } else {
      await sendMessage(ChannelMessage.isNotScreenShare);

      screenTrack?.stop();
      screenTrack?.removeAllListeners();
      await client.unpublish();
      await client.publish(Object.values(localTracks));
    }
    setScreenShareState(newScreenShareState);
  }

  async function isPublishAgain() {
    if (!client) return;
    await client.unpublish();
    await client.publish(Object.values(localTracks));
  }

  async function leave() {
    await client?.leave();

    if (localTracks.audioTrack) {
      localTracks.audioTrack.stop();
      localTracks.audioTrack.close();
    }
    if (localTracks.videoTrack) {
      localTracks.videoTrack.stop();
      localTracks.videoTrack.close();
    }
    setRemoteUsers([]);
    setJoinState(JoinState.inactive);
  }

  useEffect(() => {
    if (!client) return;

    client.startProxyServer(ProxyModes.forceTcp);

    setRemoteUsers(client.remoteUsers);

    const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      await client.subscribe(user, mediaType);

      // toggle rerender while state of remoteUsers changed.
      setRemoteUsers(() => Array.from(client.remoteUsers));
      if (mediaType == 'audio' && user.audioTrack) {
        user.audioTrack.play();
        // add to recording array
        audioTracks.current = [...audioTracks.current, user.audioTrack];
      }
    };
    const handleUserUnpublished = (user: IAgoraRTCRemoteUser, mediaType: 'audio' | 'video') => {
      setRemoteUsers(() => Array.from(client.remoteUsers));

      if (mediaType == 'audio') {
        user.audioTrack?.stop();
      }
    };

    const handleUserJoined = () => {
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };
    const handleUserLeft = () => {
      setRemoteUsers(() => Array.from(client.remoteUsers));
    };

    client.on('user-published', handleUserPublished);
    client.on('user-unpublished', handleUserUnpublished);
    client.on('user-joined', handleUserJoined);
    client.on('user-left', handleUserLeft);

    setReadyToJoin(true);

    return () => {
      client.off('user-published', handleUserPublished);
      client.off('user-unpublished', handleUserUnpublished);
      client.off('user-joined', handleUserJoined);
      client.off('user-left', handleUserLeft);
    };
  }, [client]);

  return {
    localAudioTrack,
    localVideoTrack,
    joinState,
    setJoinState,
    screenShare,
    leave,
    join,
    remoteUsers,
    isPublishAgain,
    audioTracks: audioTracks.current,
    toggleMute,
    muted,
    readyToJoin,
  };
}
