import '../callFinishedScreen/callFinishedScreen.scss';

import { useBoundStore } from '@fixzy/agent-app/src/store';
import { AppType } from '@fixzy/common-package/enums';
import Logo from '../../components/logo/Logo';

export const CallFinishedScreen = () => {
  const [appType] = useBoundStore((state) => state.appType);

  return (
    <>
      <div className='end-screen-container'>
        <div className='endcall-message-container'>
          <Logo />
          <h1 className='header-style'>
            {appType === AppType.agent
              ? 'The call with the user has ended.'
              : 'Thank you for using Fixzy Remote Assistance!'}
          </h1>
          <p className='p-style'>You may now close the browser window</p>
        </div>
      </div>
    </>
  );
};
