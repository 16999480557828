/* eslint-disable @typescript-eslint/no-unused-vars */
import './instrument';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useBoundStore } from './store';

import Error from '@fixzy/common-package/src/components/error/error';
import { AppType } from '@fixzy/common-package/enums';
import { SignalRProvider } from '@fixzy/common-package/src/hooks';
import { CallScreen } from '@fixzy/common-package/src/components/callStream/callStream';

import { CallFinishedScreen } from '@fixzy/common-package/src/pages/callFinishedScreen/callFinishedScreen';
import { useEffect } from 'react';
import faviconhref from '@fixzy/common-package/src/assets/images/favicon.png';
import { useTranslation } from 'react-i18next';
import config from '@fixzy/common-package/src/config';
import { Toaster } from 'react-hot-toast';
import clarity from '@microsoft/clarity';

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import './cookie-consent/cookie-consent-theme.css';
import * as CookieConsent from 'vanilla-cookieconsent';
import consentConfig from './cookie-consent/consent-config';

function App() {
  const { t } = useTranslation();
  const userName = 'John Doe';
  const setAccessTokenStore = useBoundStore((state) => state.setAccessTokenStore);
  const setAppType = useBoundStore((state) => state.setAppType);
  setAppType(AppType.agent);
  setAccessTokenStore('noAccessTokenNeeded', userName);

  useEffect(() => {
    clarity.init(import.meta.env.VITE_MS_CLARITY_PROJECT_AGENT);
    CookieConsent.run({
      ...consentConfig,
      onFirstConsent: ({ cookie }) => {
        if (cookie.categories.includes('analytics')) {
          clarity.consent();
        }
      },
    });
    const favicon = document.createElement('link');
    favicon.href = faviconhref;
    favicon.rel = 'icon';
    favicon.type = 'image/png';

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(favicon);

    document.title = t('Index.title');
  }, []);

  return (
    <SignalRProvider hubUrl={`${config.API_URL}/hub`}>
      <Router>
        <Routes>
          <Route path='/session/:guid' element={<CallScreen />} />
          <Route path='/endcall' element={<CallFinishedScreen />} />
          <Route path='*' element={<Error />} />
        </Routes>
      </Router>
      <Toaster />
    </SignalRProvider>
  );
}

export default App;
