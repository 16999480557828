type Position = {
  x: number;
  y: number;
};

type RelPosition = Position & {
  ratio: number;
};

type Size = {
  width: number;
  height: number;
};

type CanvasImage = {
  image: CanvasImageSource | HTMLImageElement;
  position: Position | null | undefined;
};

const clampRelativeCoord = (v: number) => (v < 0 ? 0 : v > 1 ? 1 : v);
const toRelativeX = (x: number, width: number) => x / width;
const toRelativeY = (y: number, height: number) => y / height;

const toRelPosition = (p: Position, s: Size) => {
  return {
    x: p.x / s.width,
    y: p.y / s.height,
    ratio: s.width / s.height,
  };
};

const toAbsoluteX = (v: number, width: number) => Math.round(v * width);
const toAbsoluteY = (v: number, width: number, ratio: number) => Math.round((v * width) / ratio);

const toAbsolutePosition = (p: RelPosition, width: number) => {
  return {
    x: Math.round(p.x * width),
    y: Math.round((p.y * width) / (p.ratio ? p.ratio : 1)),
  };
};

export type { Position, RelPosition, CanvasImage, Size };

export {
  clampRelativeCoord,
  toRelPosition,
  toRelativeX,
  toRelativeY,
  toAbsoluteX,
  toAbsoluteY,
  toAbsolutePosition,
};
