import './screenShareScreen.scss';

export const ScreenShareScreen = () => {
  return (
    <div className='background-page-container'>
      <div className='content-container'>
        <h2>Screen Sharing</h2>
      </div>
    </div>
  );
};
