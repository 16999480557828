import { ReactElement } from 'react';

import { ReactComponent as Cross } from '@fixzy/icon-library/src/icons/cross-record.svg';

import '../penTool/modal.scss';

interface ModalProps {
  children: JSX.Element | string;
  title?: string;
  onHide: () => void;
}

function Modal(props: ModalProps): ReactElement {
  const { children, title, onHide } = props;

  return (
    <div
      className='modal'
      role='button'
      tabIndex={0}
      onClick={() => onHide()}
      onKeyDown={() => onHide()}
    >
      <div
        className='content'
        role='button'
        tabIndex={0}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div className='head'>
          <button className='close' onClick={() => onHide()}>
            <Cross width={12} height={12} />
          </button>
          {title && <h1>{title}</h1>}
          <hr className='mb-2' />
        </div>

        {children}
      </div>
    </div>
  );
}

export default Modal;
