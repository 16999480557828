import React, { useEffect, useState, useRef, useCallback } from 'react';

import { useBoundStore } from '@fixzy/agent-app/src/store';
import { AppType, PenToolActions, PenToolRtcActions } from '@fixzy/common-package/enums';
import Modal from './modal';
import ColorPicker from './colorPicker';
import { useSignalR } from '../../hooks';

interface PenToolProps {
  width: number;
  height: number;
  style?: React.CSSProperties;
  disableDrawing: boolean;
}

type LineInfo = {
  x: number;
  y: number;
  newX: number;
  newY: number;
  aspectRatio: number;
  lineWidth: number;
  lineCap: CanvasLineCap;
  strokeStyle: string | CanvasGradient | CanvasPattern;
};

type Sketch = {
  lines: LineInfo[];
};

const PenTool = React.forwardRef<HTMLCanvasElement, PenToolProps>((props: PenToolProps, ref) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const { style, disableDrawing, width, height } = props;
  const {
    sendMessage,
    sendPayload,
    addCommandListener,
    addPayloadListener,
    connectionState,
    removeCommandListener,
    removePayloadListener,
  } = useSignalR();

  const [context, setContext] = useState<CanvasRenderingContext2D | null>(null);
  const [showColorPicker, setShowColorPicker] = useState(false);

  const contextRef = useRef(context);
  const sketches = useRef<Sketch[]>([]);
  const undoneSketch = useRef<Sketch | undefined>();

  const [appType, action, setPenToolAction] = useBoundStore((state) => [
    state.appType,
    state.action,
    state.setPenToolAction,
  ]);

  const isConsumer = appType === AppType.consumer;
  const isAgent = appType === AppType.agent;

  const lineWidth = 5;
  const lineCap = 'round';

  const strokeStyle = useRef('#475fe8');

  const toRelativeX = useCallback((x: number) => x / width, [width]);
  const toRelativeY = useCallback((y: number) => y / height, [height]);

  const toAbsoluteX = useCallback((n: number) => Math.round(n * width), [width]);
  const toAbsoluteY = useCallback((n: number, ratio: number) => (n * width) / ratio, [width]);

  const onChangeColor = (color: string) => {
    if (contextRef.current) {
      strokeStyle.current = color;
      contextRef.current.strokeStyle = color;
    }
  };

  const clear = useCallback(
    (clearSketches = false) => {
      if (canvasRef.current && contextRef.current) {
        contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

        if (clearSketches && isAgent) {
          sendMessage(PenToolRtcActions.clear);
        }

        if (clearSketches) sketches.current = [];
      }
    },
    [isAgent, sendMessage],
  );

  const drawLine = useCallback(
    (lineInfo: LineInfo, forwardAction = false) => {
      const aspectRatio = width / height;
      const ratioRatio = aspectRatio / lineInfo.aspectRatio;

      if (contextRef.current) {
        contextRef.current.strokeStyle = lineInfo.strokeStyle;
        contextRef.current.lineWidth = lineInfo.lineWidth * ratioRatio;
        contextRef.current.lineCap = lineInfo.lineCap;
        contextRef.current.beginPath();
        contextRef.current.moveTo(
          toAbsoluteX(lineInfo.x),
          toAbsoluteY(lineInfo.y, lineInfo.aspectRatio),
        );
        contextRef.current.lineTo(
          toAbsoluteX(lineInfo.newX),
          toAbsoluteY(lineInfo.newY, lineInfo.aspectRatio),
        );
        contextRef.current.stroke();

        if (forwardAction && isAgent) {
          sendPayload(PenToolRtcActions.draw, JSON.stringify(lineInfo));
        }
      }
    },
    [width, height, toAbsoluteX, toAbsoluteY, isAgent, sendPayload],
  );

  const redraw = useCallback(() => {
    for (const sketch of sketches.current) {
      for (const line of sketch.lines) {
        drawLine(line);
      }
    }
  }, [drawLine]);

  const undo = useCallback(() => {
    clear();

    if (sketches.current.length > 0) {
      // Remove the last sketch
      undoneSketch.current = sketches.current.pop();

      redraw();

      if (isAgent) {
        sendMessage(PenToolRtcActions.undo);
      }
    }
  }, [clear, redraw, isAgent, sendMessage]);

  const redo = useCallback(
    (sketch: Sketch) => {
      sketches.current.push(sketch);

      for (const line of sketch.lines) {
        drawLine(line);
      }

      if (isAgent) {
        sendPayload(PenToolRtcActions.redo, JSON.stringify(undoneSketch.current));
      }
    },
    [isAgent, drawLine, sendPayload],
  );

  useEffect(() => {
    if (connectionState === 'joined' && isConsumer) {
      const commands = [
        addCommandListener(PenToolRtcActions.clear, () => clear(true)),
        addCommandListener(PenToolRtcActions.startDrawing, () =>
          sketches.current.push({ lines: [] }),
        ),
        addCommandListener(PenToolRtcActions.undo, () => undo()),
      ];

      const payloads = [
        addPayloadListener(PenToolRtcActions.draw, (_, payload) => {
          const lineData = JSON.parse(payload);
          drawLine(lineData);
          sketches.current[sketches.current.length - 1].lines.push(lineData);
        }),
        addPayloadListener(PenToolRtcActions.redo, (_, payload) => redo(JSON.parse(payload))),
      ];

      return () => {
        commands.forEach((command) => removeCommandListener(command));
        payloads.forEach((payload) => removePayloadListener(payload));
      };
    }
  }, [
    addCommandListener,
    addPayloadListener,
    clear,
    connectionState,
    drawLine,
    isConsumer,
    redo,
    removeCommandListener,
    removePayloadListener,
    undo,
  ]);

  useEffect(() => {
    let x = 0;
    let y = 0;
    let isMouseDown = false;
    let drawLineTimeout: NodeJS.Timeout | null = null;

    const onStartDrawing = (event: MouseEvent) => {
      undoneSketch.current = undefined;

      if (canvasRef.current) {
        if (contextRef.current) {
          contextRef.current.lineWidth = lineWidth;
          contextRef.current.lineCap = lineCap;
        }
        isMouseDown = true;
        [x, y] = [event.offsetX, event.offsetY];

        // Add a new sketch
        sketches.current.push({ lines: [] });

        sendMessage(PenToolRtcActions.startDrawing);
      }
    };

    const onStopDrawing = () => {
      isMouseDown = false;
    };

    const onDrawLine = async (event: MouseEvent) => {
      if (contextRef.current && isMouseDown) {
        if (!drawLineTimeout) {
          drawLineTimeout = setTimeout(() => {
            const line = {
              x: toRelativeX(x),
              y: toRelativeY(y),
              newX: toRelativeX(event.offsetX),
              newY: toRelativeY(event.offsetY),
              aspectRatio: width / height,
              lineWidth: lineWidth,
              lineCap: lineCap,
              strokeStyle: strokeStyle.current,
            } as LineInfo;

            drawLine(line, true);

            // Store all the lines
            sketches.current[sketches.current.length - 1].lines.push(line);

            drawLineTimeout = null;

            [x, y] = [event.offsetX, event.offsetY];
          }, 20);
        }
      }
    };

    // Externally triggered events

    if (action) {
      if (action === PenToolActions.undo) {
        undo();
      }

      if (action === PenToolActions.redo) {
        if (undoneSketch.current) {
          redo(undoneSketch.current);
          undoneSketch.current = undefined;
        }
      }

      if (action === PenToolActions.clear) {
        clear(true);
      }

      if (action === PenToolActions.toggleColorPicker) {
        setShowColorPicker(true);
      }

      setPenToolAction(null);
    }

    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const canvasContext = canvas.getContext('2d');

      setContext(canvasContext);
      contextRef.current = canvasContext;

      if (drawLineTimeout) clearTimeout(drawLineTimeout);

      if (!disableDrawing && canvasContext) {
        canvas.addEventListener('mousedown', onStartDrawing);
        canvas.addEventListener('mousemove', onDrawLine);
        canvas.addEventListener('mouseup', onStopDrawing);
        canvas.addEventListener('mouseout', onStopDrawing);
      }
    }

    clear();
    redraw();

    return () => {
      const canvas = canvasRef.current;

      if (canvas) {
        canvas.removeEventListener('mousedown', onStartDrawing);
        canvas.removeEventListener('mousemove', onDrawLine);
        canvas.removeEventListener('mouseup', onStopDrawing);
        canvas.removeEventListener('mouseout', onStopDrawing);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    action,
    context,
    disableDrawing,
    height,
    width,
    isConsumer,
    style,
    clear,
    drawLine,
    undo,
    redo,
    setPenToolAction,
    toRelativeX,
    toRelativeY,
    redraw,
    sendMessage,
  ]);

  return (
    <>
      <canvas
        style={style}
        width={width}
        height={height}
        ref={(node) => {
          canvasRef.current = node;
          // Allow ref forwarding
          if (typeof ref === 'function') ref(node);
          else if (ref) ref.current = node;
        }}
      ></canvas>

      {showColorPicker && (
        <Modal title='Selection' onHide={() => setShowColorPicker(false)}>
          <ColorPicker
            selectedColor={strokeStyle.current}
            onChange={(colour) => {
              setShowColorPicker(false);
              onChangeColor(colour);
            }}
          />
        </Modal>
      )}
    </>
  );
});

PenTool.displayName = 'PenTool';

export default PenTool;
