import { useState, ReactElement } from 'react';

import '../penTool/color-picker.scss';

interface ColorPickerProps {
  selectedColor: string;
  onChange?: (color: string) => void;
}

function ColorPicker(props: ColorPickerProps): ReactElement {
  const colors = [
    '#b11b2b',
    '#475fe8',
    '#4eb2cd',
    '#87f99f',
    '#333c86',
    '#da33e1',
    '#e57a2f',
    '#b9b6c1',
    '#ffffff',
    '#000000',
  ];

  const selectedBorderColor = '#475fe8';

  const { onChange } = props;

  const [selectedColor, setSelectedColor] = useState(props.selectedColor);

  const onSelectColor = (color: string) => {
    setSelectedColor(color);
    if (onChange) onChange(color);
  };

  return (
    <div className='color-picker'>
      {colors.map((color) => (
        <button
          key={color}
          onClick={() => onSelectColor(color)}
          style={selectedColor === color ? { borderColor: selectedBorderColor } : {}}
        >
          <div className='dot' style={{ backgroundColor: color }} />
        </button>
      ))}
    </div>
  );
}

export default ColorPicker;
