export enum AppType {
  agent = 'agent',
  consumer = 'consumer',
}

export enum ChannelMessage {
  isScreenShare = 'isScreenShare',
  isNotScreenShare = 'isNotScreenShare',
  openApp = 'openApp',
  isRecording = 'isRecording',
  isNotRecording = 'isNotRecording',
  callHasEnded = 'callHasEnded',
  appBackgrounded = 'appBackgrounded',
  appForegrounded = 'appForegrounded',
  paused = 'paused',
  unpaused = 'unpaused',
  appJoined = 'appJoined',
  appEndedCall = 'appEndedCall',
  isStreamNotSent = 'isStreamNotSent',
  isStreamSent = 'isStreamSent',
  openingMeasureApp = 'openingMeasureApp',
  isUploading = 'isUploading',
  isNotUploading = 'isNotUploading',
}

export enum MenuItems {
  penTool,
  pointer,
}

export enum PenToolActions {
  undo = 1,
  redo = 2,
  clear = 3,
  toggleColorPicker = 4,
}

export enum PenToolRtcActions {
  startDrawing = 'startDrawing',
  draw = 'draw',
  clear = 'clear',
  undo = 'undo',
  redo = 'redo',
}

export enum PointerRtcActions {
  updatePosition = 'updatePosition',
  resetPosition = 'resetPosition',
}

export enum JoinState {
  inactive = 0,
  joining = 1,
  joined = 2,
}

export enum ProxyModes {
  none = 0,
  forceUdp = 3,
  forceTcp = 5,
}
