import { useBoundStore } from '@fixzy/agent-app/src/store';
import '../backgroundScreen/backgroundScreen.scss';
interface BackgroundScreenProps {
  name: string;
}

export const BackgroundScreen = ({ name }: BackgroundScreenProps) => {
  const openingMeasureApp = useBoundStore((state) => state.openingMeasureApp);

  return (
    <div className='background-page-container'>
      <div className='caller-container'>
        <h2 className='user-name'>{name}</h2>
        <p className='call-connecting'>
          {openingMeasureApp ? 'Is opening the Fixzy Measure app' : 'Has minimised the app'}
        </p>
      </div>
    </div>
  );
};
