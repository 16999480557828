import { ReactComponent as ArrowDownTray } from '@fixzy/icon-library/src/icons/arrow-down-tray.svg';
import { useBoundStore } from '@fixzy/agent-app/src/store';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';

import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../spinner/spinner';
import axios from 'axios';

export const VideoGallery = () => {
  const takenVideos = useBoundStore((state) => state.takenVideos);

  const downloadFile = async (filePath: string, timeStamp: string) => {
    try {
      // TODO - remove the replace when backend is fixed
      const response = await axios.get(filePath.replace('http:', 'https:'), {
        responseType: 'blob', // Important to get the file as a Blob
      });

      // Create a Blob URL
      const url = URL.createObjectURL(
        new Blob([response.data], { type: response.headers['content-type'] }),
      );

      // Create a link element and trigger download
      const a = document.createElement('a');
      a.href = url;
      a.download = `recording_${timeStamp}.mp4`; // Specify the name of the downloaded file
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <div className='flex h-1/2 flex-col '>
      <div className='flex items-center mb-2 px-4 pt-4'>
        <h3 className='text-gray-800'>Videos</h3>
        {takenVideos.length > 0 && (
          <p className='bg-secondary-100 text-sm text-secondary-700 rounded-full text-lg ml-2 px-2 py-1'>
            {takenVideos.length} {takenVideos.length === 1 ? 'item' : 'items'}
          </p>
        )}
      </div>

      {takenVideos.length === 0 ? (
        <div className='flex-1 flex-col flex justify-center items-center bg-white rounded-xl mx-4 mb-4'>
          <div className='h-[50px] w-[50px] rounded-full bg-secondary-100 flex justify-center items-center'>
            <FontAwesomeIcon icon={faVideo} className='text-xl text-secondary-700' />
          </div>
          <h4 className='text-gray-800'>No Videos</h4>
          <p>Press &quot;Record&quot; to take a video</p>
        </div>
      ) : (
        <div className='w-full overflow-y-auto'>
          <div className='flex flex-wrap pl-2'>
            {takenVideos.map((video, index) => (
              <div key={video.timeStamp || `video_${index}`} className='w-1/3 pr-2 pb-2'>
                <div className='flex flex-1 bg-white h-[250px] rounded-xl justify-center items-center overflow-hidden'>
                  {video.uploading ? (
                    <div className='flex flex-col justify-center items-center'>
                      <Spinner />
                      <p className='text-gray-600 text-xs font-medium mt-1'>Uploading...</p>
                    </div>
                  ) : (
                    <div className='relative h-full w-full'>
                      <img
                        src={video.thumbnailPath}
                        alt='#'
                        className='h-full'
                        data-clarity-mask='true'
                      />
                      <div className='absolute top-0 left-0 right-0 bg-gray-100 py-1 flex items-center justify-center'>
                        <p className='text-gray-800 text-xs font-medium'>
                          {dayjs(video.timeStamp).format('DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                      <button
                        className='absolute bottom-2 left-2 right-2 flex flex-1 bg-secondary-700 py-2 rounded-xl justify-center items-center cursor:pointer hover:bg-secondary-500'
                        onClick={() => downloadFile(video.filePath, video.timeStamp)}
                      >
                        <ArrowDownTray height='20px' width='20px' className='text-white mr-2' />
                        <p className='text-white font-bold text-md'>Download</p>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
