import { ReactComponent as ChevronRight } from '@fixzy/icon-library/src/icons/chevron-right.svg';
import { ReactComponent as ChevronLeft } from '@fixzy/icon-library/src/icons/chevron-left.svg';
import { ReactComponent as Cross } from '@fixzy/icon-library/src/icons/cross.svg';

import { useBoundStore } from '@fixzy/agent-app/src/store';

interface ImagePreviewProps {
  previewIndex: number;
  setPreviewIndex: (val: number | null) => void;
}

export const ImagePreview = ({ previewIndex, setPreviewIndex }: ImagePreviewProps) => {
  const takenImages = useBoundStore((state) => state.takenImages);
  const image = takenImages[previewIndex];

  return (
    <div className='flex justify-center items-center absolute top-0 right-0 bottom-0 left-0 bg-primary-700/70 gap-2'>
      {previewIndex > 0 ? (
        <div className='h-[70px] w-[70px] rounded-full flex justify-center items-center bg-secondary-700 hover:cursor-pointer hover:bg-secondary-500'>
          <ChevronLeft
            className='text-white h-[50px] w-[50px] mr-1'
            onClick={() => setPreviewIndex(previewIndex - 1)}
          />
        </div>
      ) : (
        <div className='w-[70px]' />
      )}
      <div className='h-[500px] flex justify-center items-center'>
        {image.uploading ? (
          <p>Uploading...</p>
        ) : (
          <img src={image.src} alt='#' className='img-preview' />
        )}
      </div>
      {previewIndex < takenImages.length - 1 ? (
        <div className='h-[70px] w-[70px] rounded-full flex justify-center items-center bg-secondary-700 hover:cursor-pointer hover:bg-secondary-500'>
          <ChevronRight
            className='text-white h-[50px] w-[50px]  ml-1'
            onClick={() => setPreviewIndex(previewIndex + 1)}
          />
        </div>
      ) : (
        <div className='w-[70px]' />
      )}
      <div className='absolute top-4 right-4 h-[70px] w-[70px] rounded-full flex justify-center items-center bg-white hover:cursor-pointer hover:bg-secondary-100'>
        <Cross
          className='text-secondary-700 h-[50px] w-[50px]'
          onClick={() => setPreviewIndex(null)}
        />
      </div>
    </div>
  );
};
